import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  loading: false,
  sidebarUnfoldable: true,
  requestSentCount: 0,
}

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSideBarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setBarUnfoldAble: (state, action) => {
      state.sidebarUnfoldable = action.payload
    },
    setRequestSentCount: (state, action) => {
      state.requestSentCount =
        action.payload === '+' ? state.requestSentCount + 1 : state.requestSentCount - 1
    },
  },
})
export const { setLoading, setSideBarShow, setBarUnfoldAble, setRequestSentCount } =
  AppSlice.actions
export default AppSlice.reducer
