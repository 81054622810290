import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import appReducer from './app.slice'

const reducersToPersist = [appReducer]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: reducersToPersist,
}

const reducers = combineReducers({
  app: appReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
})

const persistor = persistStore(store)

export { store, persistor }
