import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { TailSpin } from 'react-loader-spinner'

import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import { useSelector } from 'react-redux'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {
  const loadingData = useSelector((state) => state.app.requestSentCount)

  return (
    <div>
      <TailSpin
        height="80"
        width="80"
        color="#321fdb"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          display: loadingData > 0 ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        wrapperClass=""
      />
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route exact path="/register" name="Register" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>

        <ToastContainer />
      </HashRouter>
    </div>
  )
}

export default App
